<div class="question" [ngClass]="'scroll-to-' + index">
  <header> {{ index + 1}} </header>
  <div class="question-content">
    <h4> {{question.text[0].text}} </h4>
    <mat-radio-group (change)="onQuestionChange(index)">
      <mat-radio-button id={{answer._id}} (click)='addItem(question._id,answer._id)'
                        *ngFor="let answer of question.answers" [value]='answer.text[0].text'
                        [disabled]="disabled"> {{answer.text[0].text}} </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
