import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './app.material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCaptchaModule } from 'ngx-captcha';

// Services
import { EventManagerService } from './services/event-manager.service';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { RadioQuestionComponent } from './components/radio-question/radio-question.component';
import { OutcomeComponent } from './components/outcome/outcome.component';
import { SurveyOutcomeComponent } from './components/survey-outcome/survey-outcome.component';
import { PreloaderComponent } from './components/preloader/preloader.component';

export function createHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    QuestionnaireComponent,
    RadioQuestionComponent,
    OutcomeComponent,
    SurveyOutcomeComponent,
    PreloaderComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
  ],
  providers: [
    EventManagerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
