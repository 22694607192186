import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { GenericResponse } from '../model/common';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {

  constructor(private http: HttpClient) { }

  public getQuestionnaire(questionnaireId: string, panelistId: string, supplierId: string, lang: string) {
    let params = new HttpParams();
    params = params.append('panelistId', panelistId);
    params = params.append('supplierId', supplierId);
    params = params.append('lang', lang);

    const options = HttpUtils.getJsonContentTypeHeader();
    options['params'] = params;

    return this.http.get<GenericResponse>(`${environment.api_url}/v1/web/questionnaire/${questionnaireId}`, options);
  }
}
