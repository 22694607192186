import { Component, OnInit, ElementRef, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { ParticipationService } from 'src/app/services/participation.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { SurveyStatusService } from 'src/app/services/survey-status.service';
import { SecurityOptionsService } from '../../services/security-options.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { HeaderContent } from 'src/app/model/questionnaire/header';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { helpers } from 'src/app/util/helpers';
import { environment } from 'src/environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  public questionnaireHttpSubscriber: Subscription;
  public answeredQuestions: number = 0;
  public completedQuestionsPercentage$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private currentLang: string;
  public isSubmitted = false;
  public panelistId: string;
  public questionnaireForm: FormGroup;
  public questionnaireHoneypotForm: FormGroup;
  public questionnaireId: string;
  public questions: object[] = [];
  public questionHoneypot: any;
  public supplierId: string;
  private surveyStatus: any;

  public hasBotDetection: boolean = false;

  public isCaptchaReady: boolean = false;
  public captchaLang: string;
  public reCaptcha: string = '';
  public reCaptchaSiteKey: string = environment.reCaptchaSiteKey;
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  constructor(private questionnaireService: QuestionnaireService,
              private participationService: ParticipationService,
              private surveyStatusService: SurveyStatusService,
              private securityOptionsService: SecurityOptionsService,
              private snackBarService: SnackBarService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private router: Router,
              private headerDataService: HeaderDataService,
              private translate: TranslateService,
              private element: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.scrollToNext = this.scrollToNext.bind(this);
    this.questionnaireId = this.route.snapshot.queryParams.id;
    this.panelistId = this.route.snapshot.queryParams.panelistId;
    this.supplierId = this.route.snapshot.queryParams.supplierId;
    this.currentLang = this.route.snapshot.queryParams.lang;

    const currentTwoLettersLang = helpers.getFirstTwoCharacters(this.currentLang || '', true);

    if (this.currentLang) {
      this.translate.use(currentTwoLettersLang).subscribe().add(() => {
        this.captchaLang = this.translate.currentLang;
      });
    }

    if (this.invalidUrl()) return this.router.navigate(['/questionnaire/invalid_url']);

    this.surveyStatusService.getStatus().subscribe(
      (res) => {
        this.surveyStatus = res.payload.surveyStatus;
      },
      (error) => {
        this.handleError('Failed to get survey status data');
      },
    );

    this.questionnaireHttpSubscriber =
      this.questionnaireService.getQuestionnaire(this.questionnaireId, this.panelistId, this.supplierId, this.currentLang).subscribe(
       async (res) => {
         if (res.payload.questions.length) {
           this.hasBotDetection = await this.isSecurityBotDetection(res.payload.securityOptions);

           const headerContent: HeaderContent = {
             title: res.payload.title,
             author: res.payload.author,
           };

           this.headerDataService.setHeaderContent(headerContent);
           this.questionHoneypot = res.payload.questions.filter(question => question['_id'].length <= 12);
           this.questionnaireHoneypotForm = this.formBuilder.group({
             questionId: this.formBuilder.control(this.questionHoneypot[0]['_id']),
             answers: this.formBuilder.control(''),
           });
           this.questions = res.payload.questions.filter(question => question['_id'].length > 12);
         } else {
           this.router.navigate(['/questionnaire/project_went_wrong']);
         }
       },
       (error) => {
         if (error.status === 403) {
           switch (error.error.code.toLowerCase()) {
             case 'answered':
               this.router.navigate(['/questionnaire/project_answered']);
               break;
             case 'complete':
               this.router.navigate(['/questionnaire/project_completed']);
               break;
             default:
               this.router.navigate(['/questionnaire/project_not_available']);
               break;
           }
         } else if (error.status === 500) {
           this.router.navigate(['/questionnaire/project_not_available']);
         } else if (error.status === 502) {
           this.router.navigate(['/questionnaire/invalid_url']);
         } else {
           this.handleError('Failed to fetch the questionnaire');
         }
       },
    );

    this.questionnaireForm = this.formBuilder.group({
      answers: this.formBuilder.array([]),
    });

    this.completedQuestionsPercentage$.subscribe((width: number) => {
      this.renderPrimaryProgressBar(width);
    });
  }

  ngOnDestroy(): void {
    if (this.questionnaireHttpSubscriber) {
      this.questionnaireHttpSubscriber.unsubscribe();
    }
  }

  isSecurityBotDetection(securityOptions): Promise<boolean> {
    return new Promise((resolve) => {
      this.securityOptionsService.getOptions().subscribe(
        (res) => {
          const isEnabled = securityOptions.some((opt) => {
            return opt.code === res.payload.securityOptions.BOT_DETECTION && opt.enabled;
          });

          resolve(isEnabled);
        },
        (error) => {
          this.handleError('Failed to get security options data');
        },
      );
    });
  }

  renderPrimaryProgressBar(width: number): void {
    this.renderer.setStyle(
      this.element.nativeElement.getElementsByClassName('mat-progress-bar-primary')[0],
      'width',
      `${width}%`,
    );
  }

  doCaptchaCheck() {
    if (this.hasBotDetection && this.isCaptchaReady) {
      return this.captchaElem.execute();
    }

    this.onSubmit();
  }

  handleCaptchaReady() {
    this.isCaptchaReady = true;
  }

  handleCaptchaSuccess(captchaToken: string) {
    this.onSubmit(captchaToken);
  }

  public onSubmit(captchaToken: string = '') {
    if (this.isQuestionnaireValid()) {
      const currentHoneypotAnswers = this.questionnaireHoneypotForm.value;
      const currentAnswers = this.questionnaireForm.value.answers;

      this.isSubmitted = true;

      if (currentHoneypotAnswers && currentHoneypotAnswers.answers) {
        currentAnswers.push(currentHoneypotAnswers);
      }

      this.participationService.createParticipation(
        this.questionnaireId,
        this.panelistId,
        this.supplierId,
        this.currentLang,
        currentAnswers,
        captchaToken,
      ).subscribe(
        (res) => {
          if (res.payload.outcome === this.surveyStatus.COMPLETE) {
            this.router.navigate(['/questionnaire/complete'], { queryParams: { survey_url: res.payload.redirectUrl } });
          } else if (res.payload.outcome === this.surveyStatus.TERMINATE || res.payload.outcome === this.surveyStatus.OVER_QUOTA) {
            this.router.navigate(['/questionnaire/terminate'], { queryParams: { supplier_url: res.payload.redirectUrl } });
          } else {
            this.router.navigate(['/questionnaire/security_terminate'], { queryParams: { supplier_url: res.payload.redirectUrl } });
          }
        },
        (error) => {
          this.handleError('Failed to post the questionnaire');
        },
      );
    } else {
      this.handleError('Please answer all of the questions');
    }
  }

  public isQuestionnaireValid() {
    return this.questionnaireForm.value.answers.length === this.questions.length;
  }

  private invalidUrl() {
    const { id, supplierId, panelistId, lang } = this.route.snapshot.queryParams;
    return !id || !supplierId || !panelistId || !lang;
  }

  private handleError(message) {
    this.snackBarService.errorWithClose(message);
  }

  public scrollToNext(currentIndex) {
    const searchedIndex = currentIndex + 1;

    const elements = document.getElementsByClassName(`scroll-to-${searchedIndex}`);

    if (elements.length > 0) {
      const questionnaireRect = document.getElementsByClassName('questionnaire')[0].getBoundingClientRect();
      const elementRect = elements[0].getBoundingClientRect();
      window.scrollTo({
        behavior: 'smooth',
        left: elementRect.left - questionnaireRect.left,
        top: elementRect.top - questionnaireRect.top,
      });
    }
  }
}
