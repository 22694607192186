import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SecurityOptionsService = /** @class */ (function () {
    function SecurityOptionsService(http) {
        this.http = http;
    }
    SecurityOptionsService.prototype.getOptions = function () {
        return this.http.get(environment.api_url + "/v1/web/security_options", HttpUtils.getJsonContentTypeHeader());
    };
    SecurityOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityOptionsService_Factory() { return new SecurityOptionsService(i0.ɵɵinject(i1.HttpClient)); }, token: SecurityOptionsService, providedIn: "root" });
    return SecurityOptionsService;
}());
export { SecurityOptionsService };
