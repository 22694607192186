import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../util/http-util';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../model/common';

@Injectable({ providedIn: 'root' })
export class SurveyStatusService {

  constructor(private http: HttpClient) {}

  public getStatus() {
    return this.http.get<GenericResponse>(`${environment.api_url}/v1/web/survey_status`,
                                          HttpUtils.getJsonContentTypeHeader());
  }
}
