import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticipationService } from 'src/app/services/participation.service';

@Component({
  selector: 'app-survey-outcome',
  templateUrl: './survey-outcome.component.html',
  styleUrls: ['./survey-outcome.component.scss'],
})

export class SurveyOutcomeComponent implements OnInit {
  public panelistId: string;
  public projectId: string;
  public status: number;

  constructor(private participationService: ParticipationService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.queryParams.id;
    this.panelistId = this.route.snapshot.queryParams.panelistId;
    this.status = this.route.snapshot.queryParams.status;

    this.participationService.updateParticipation(this.panelistId, this.status).subscribe(
      (res) => {
        if (res.payload.redirectionUrl.indexOf('http') === -1) {
          this.router.navigate(['/questionnaire/invalid_url']);
        } else {
          window.location.href = res.payload.redirectionUrl;
        }
      },
      () => {
        this.router.navigate(['/questionnaire/invalid_url']);
      },
    );
  }
}
