<app-header></app-header>
<div class="questionnaire container">
  <section class="welcome">
    <p class="large-font">{{ translationString('HEADING') | translate }},</p>
    <p class="large-font sub-description">{{ translationString('SUB_HEADING') | translate }}</p>
    <p class="mt-25 description" [innerHtml]="translationString('DESCRIPTION') | translate"></p>
    <div *ngIf="isAccepted()">
      <a [href]="redirectSurveyUrl">
        <button  mat-raised-button class="width-200 dark-blue mt-25">
          {{ translationString('BUTTON_TEXT') | translate }}
        </button>
      </a>
      <p class="redirect-text">{{ translationString('REDIRECTION_TEXT') | translate}}</p>
    </div>
    <div *ngIf="!isAccepted()">
      <p class="redirect-text">{{ translationString('REDIRECTION_TEXT') | translate}}</p>
    </div>
  </section>
</div>
