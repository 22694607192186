import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { helpers } from 'src/app/util/helpers';
import { environment } from 'src/environments/environment';
var QuestionnaireComponent = /** @class */ (function () {
    function QuestionnaireComponent(questionnaireService, participationService, surveyStatusService, securityOptionsService, snackBarService, route, formBuilder, router, headerDataService, translate, element, renderer) {
        this.questionnaireService = questionnaireService;
        this.participationService = participationService;
        this.surveyStatusService = surveyStatusService;
        this.securityOptionsService = securityOptionsService;
        this.snackBarService = snackBarService;
        this.route = route;
        this.formBuilder = formBuilder;
        this.router = router;
        this.headerDataService = headerDataService;
        this.translate = translate;
        this.element = element;
        this.renderer = renderer;
        this.answeredQuestions = 0;
        this.completedQuestionsPercentage$ = new BehaviorSubject(0);
        this.isSubmitted = false;
        this.questions = [];
        this.hasBotDetection = false;
        this.isCaptchaReady = false;
        this.reCaptcha = '';
        this.reCaptchaSiteKey = environment.reCaptchaSiteKey;
    }
    QuestionnaireComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.scrollToNext = this.scrollToNext.bind(this);
        this.questionnaireId = this.route.snapshot.queryParams.id;
        this.panelistId = this.route.snapshot.queryParams.panelistId;
        this.supplierId = this.route.snapshot.queryParams.supplierId;
        this.currentLang = this.route.snapshot.queryParams.lang;
        var currentTwoLettersLang = helpers.getFirstTwoCharacters(this.currentLang || '', true);
        if (this.currentLang) {
            this.translate.use(currentTwoLettersLang).subscribe().add(function () {
                _this.captchaLang = _this.translate.currentLang;
            });
        }
        if (this.invalidUrl())
            return this.router.navigate(['/questionnaire/invalid_url']);
        this.surveyStatusService.getStatus().subscribe(function (res) {
            _this.surveyStatus = res.payload.surveyStatus;
        }, function (error) {
            _this.handleError('Failed to get survey status data');
        });
        this.questionnaireHttpSubscriber =
            this.questionnaireService.getQuestionnaire(this.questionnaireId, this.panelistId, this.supplierId, this.currentLang).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var _a, headerContent;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!res.payload.questions.length) return [3 /*break*/, 2];
                            _a = this;
                            return [4 /*yield*/, this.isSecurityBotDetection(res.payload.securityOptions)];
                        case 1:
                            _a.hasBotDetection = _b.sent();
                            headerContent = {
                                title: res.payload.title,
                                author: res.payload.author,
                            };
                            this.headerDataService.setHeaderContent(headerContent);
                            this.questionHoneypot = res.payload.questions.filter(function (question) { return question['_id'].length <= 12; });
                            this.questionnaireHoneypotForm = this.formBuilder.group({
                                questionId: this.formBuilder.control(this.questionHoneypot[0]['_id']),
                                answers: this.formBuilder.control(''),
                            });
                            this.questions = res.payload.questions.filter(function (question) { return question['_id'].length > 12; });
                            return [3 /*break*/, 3];
                        case 2:
                            this.router.navigate(['/questionnaire/project_went_wrong']);
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                if (error.status === 403) {
                    switch (error.error.code.toLowerCase()) {
                        case 'answered':
                            _this.router.navigate(['/questionnaire/project_answered']);
                            break;
                        case 'complete':
                            _this.router.navigate(['/questionnaire/project_completed']);
                            break;
                        default:
                            _this.router.navigate(['/questionnaire/project_not_available']);
                            break;
                    }
                }
                else if (error.status === 500) {
                    _this.router.navigate(['/questionnaire/project_not_available']);
                }
                else if (error.status === 502) {
                    _this.router.navigate(['/questionnaire/invalid_url']);
                }
                else {
                    _this.handleError('Failed to fetch the questionnaire');
                }
            });
        this.questionnaireForm = this.formBuilder.group({
            answers: this.formBuilder.array([]),
        });
        this.completedQuestionsPercentage$.subscribe(function (width) {
            _this.renderPrimaryProgressBar(width);
        });
    };
    QuestionnaireComponent.prototype.ngOnDestroy = function () {
        if (this.questionnaireHttpSubscriber) {
            this.questionnaireHttpSubscriber.unsubscribe();
        }
    };
    QuestionnaireComponent.prototype.isSecurityBotDetection = function (securityOptions) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.securityOptionsService.getOptions().subscribe(function (res) {
                var isEnabled = securityOptions.some(function (opt) {
                    return opt.code === res.payload.securityOptions.BOT_DETECTION && opt.enabled;
                });
                resolve(isEnabled);
            }, function (error) {
                _this.handleError('Failed to get security options data');
            });
        });
    };
    QuestionnaireComponent.prototype.renderPrimaryProgressBar = function (width) {
        this.renderer.setStyle(this.element.nativeElement.getElementsByClassName('mat-progress-bar-primary')[0], 'width', width + "%");
    };
    QuestionnaireComponent.prototype.doCaptchaCheck = function () {
        if (this.hasBotDetection && this.isCaptchaReady) {
            return this.captchaElem.execute();
        }
        this.onSubmit();
    };
    QuestionnaireComponent.prototype.handleCaptchaReady = function () {
        this.isCaptchaReady = true;
    };
    QuestionnaireComponent.prototype.handleCaptchaSuccess = function (captchaToken) {
        this.onSubmit(captchaToken);
    };
    QuestionnaireComponent.prototype.onSubmit = function (captchaToken) {
        var _this = this;
        if (captchaToken === void 0) { captchaToken = ''; }
        if (this.isQuestionnaireValid()) {
            var currentHoneypotAnswers = this.questionnaireHoneypotForm.value;
            var currentAnswers = this.questionnaireForm.value.answers;
            this.isSubmitted = true;
            if (currentHoneypotAnswers && currentHoneypotAnswers.answers) {
                currentAnswers.push(currentHoneypotAnswers);
            }
            this.participationService.createParticipation(this.questionnaireId, this.panelistId, this.supplierId, this.currentLang, currentAnswers, captchaToken).subscribe(function (res) {
                if (res.payload.outcome === _this.surveyStatus.COMPLETE) {
                    _this.router.navigate(['/questionnaire/complete'], { queryParams: { survey_url: res.payload.redirectUrl } });
                }
                else if (res.payload.outcome === _this.surveyStatus.TERMINATE || res.payload.outcome === _this.surveyStatus.OVER_QUOTA) {
                    _this.router.navigate(['/questionnaire/terminate'], { queryParams: { supplier_url: res.payload.redirectUrl } });
                }
                else {
                    _this.router.navigate(['/questionnaire/security_terminate'], { queryParams: { supplier_url: res.payload.redirectUrl } });
                }
            }, function (error) {
                _this.handleError('Failed to post the questionnaire');
            });
        }
        else {
            this.handleError('Please answer all of the questions');
        }
    };
    QuestionnaireComponent.prototype.isQuestionnaireValid = function () {
        return this.questionnaireForm.value.answers.length === this.questions.length;
    };
    QuestionnaireComponent.prototype.invalidUrl = function () {
        var _a = this.route.snapshot.queryParams, id = _a.id, supplierId = _a.supplierId, panelistId = _a.panelistId, lang = _a.lang;
        return !id || !supplierId || !panelistId || !lang;
    };
    QuestionnaireComponent.prototype.handleError = function (message) {
        this.snackBarService.errorWithClose(message);
    };
    QuestionnaireComponent.prototype.scrollToNext = function (currentIndex) {
        var searchedIndex = currentIndex + 1;
        var elements = document.getElementsByClassName("scroll-to-" + searchedIndex);
        if (elements.length > 0) {
            var questionnaireRect = document.getElementsByClassName('questionnaire')[0].getBoundingClientRect();
            var elementRect = elements[0].getBoundingClientRect();
            window.scrollTo({
                behavior: 'smooth',
                left: elementRect.left - questionnaireRect.left,
                top: elementRect.top - questionnaireRect.top,
            });
        }
    };
    return QuestionnaireComponent;
}());
export { QuestionnaireComponent };
