import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var SnackBarService = /** @class */ (function () {
    function SnackBarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackBarService.prototype.errorWithClose = function (messageKey, duration) {
        if (duration === void 0) { duration = 10000; }
        this.show(messageKey, 'close', duration, null);
    };
    SnackBarService.prototype.successWithClose = function (messageKey, duration, panelClass) {
        if (duration === void 0) { duration = 10000; }
        if (panelClass === void 0) { panelClass = ['alert', 'success']; }
        this.show(messageKey, 'close', duration, panelClass);
    };
    SnackBarService.prototype.show = function (messageKey, buttonLabelKey, duration, panelClass) {
        if (duration === void 0) { duration = 10000; }
        this.snackBar.open(messageKey, buttonLabelKey, { duration: duration, panelClass: panelClass });
    };
    SnackBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
    return SnackBarService;
}());
export { SnackBarService };
