/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./survey-outcome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../services/header-data.service";
import * as i5 from "./survey-outcome.component";
import * as i6 from "../../services/participation.service";
import * as i7 from "@angular/router";
var styles_SurveyOutcomeComponent = [i0.styles];
var RenderType_SurveyOutcomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveyOutcomeComponent, data: {} });
export { RenderType_SurveyOutcomeComponent as RenderType_SurveyOutcomeComponent };
export function View_SurveyOutcomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.HeaderDataService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SurveyOutcomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-survey-outcome", [], null, null, null, View_SurveyOutcomeComponent_0, RenderType_SurveyOutcomeComponent)), i1.ɵdid(1, 114688, null, 0, i5.SurveyOutcomeComponent, [i6.ParticipationService, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyOutcomeComponentNgFactory = i1.ɵccf("app-survey-outcome", i5.SurveyOutcomeComponent, View_SurveyOutcomeComponent_Host_0, {}, {}, []);
export { SurveyOutcomeComponentNgFactory as SurveyOutcomeComponentNgFactory };
