import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ParticipationService = /** @class */ (function () {
    function ParticipationService(http) {
        this.http = http;
    }
    ParticipationService.prototype.createParticipation = function (projectId, panelistId, supplierId, lang, answers, captchaToken) {
        var request = {
            answers: answers,
            panelistId: panelistId,
            projectId: projectId,
            supplierId: supplierId,
            lang: lang,
            captchaToken: captchaToken,
        };
        return this.http.post(environment.api_url + "/v1/web/participation/", request, HttpUtils.getJsonContentTypeHeader());
    };
    ParticipationService.prototype.updateParticipation = function (panelistId, surveyStatus) {
        var request = {
            panelistId: panelistId,
            surveyStatus: surveyStatus,
        };
        return this.http.patch(environment.api_url + "/v1/web/participation/", request, HttpUtils.getJsonContentTypeHeader());
    };
    ParticipationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParticipationService_Factory() { return new ParticipationService(i0.ɵɵinject(i1.HttpClient)); }, token: ParticipationService, providedIn: "root" });
    return ParticipationService;
}());
export { ParticipationService };
