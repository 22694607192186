import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-outcome',
  templateUrl: './outcome.component.html',
  styleUrls: ['./outcome.component.scss'],
})

export class OutcomeComponent implements OnInit {

  public currentOutcome: string;
  public redirectSurveyUrl: string;
  public redirectSupplierUrl: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentOutcome = this.route.snapshot.params.outcome;
    this.redirectSurveyUrl = this.route.snapshot.queryParams.survey_url;
    this.redirectSupplierUrl = this.route.snapshot.queryParams.supplier_url;

    if (this.isAccepted() && this.redirectSurveyUrl) {

      this.redirect(5000, this.redirectSurveyUrl);

    } else if (!this.isAccepted()  && this.redirectSupplierUrl) {

      this.redirect(5000, this.redirectSupplierUrl);
    }
  }

  public redirect(delay: number, url: string) {
    setTimeout(() => {
      window.location.href = url;
    },         delay);
  }

  public isAccepted() {
    return this.currentOutcome === 'complete';
  }

  public translationString(key: string) {
    return`OUTCOME.${this.currentOutcome.toUpperCase()}.${key}`;
  }
}
