// Misc. helper functions go here

export const helpers = {
  /**
   * @description Find the first two characters string
   * @param {string} str Text you want to find characters
   * @param {boolean} isToLowerCase the str return to lower case
   *
   * @returns {string} the first two characters string
   *
   * @example
   * <pre>
   *  const { helpers } = include('src/app/util/helpers');
   *  helpers.getFirstTwoCharacters(str, true);
   * </pre>
   */
  getFirstTwoCharacters(str, isToLowerCase) {
    return isToLowerCase ? str.substring(0, 2).toLowerCase() : str.substring(0, 2);
  },
};
