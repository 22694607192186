<app-header></app-header>

<app-preloader [loader]="(questionnaireHttpSubscriber)" [isFullScreen]="true">
  <div class="questionnaire container"
       *ngIf="questions.length">
    <section class="welcome">
      <p class="large-font">{{ 'QUESTIONNAIRE.WELCOME_TEXT.HEADING' | translate }}</p>
      <p class="large-font">{{ 'QUESTIONNAIRE.WELCOME_TEXT.SUB_HEADING' | translate }}</p>
      <p class="mt-25">{{ 'QUESTIONNAIRE.WELCOME_TEXT.DESCRIPTION' | translate }} </p>
      <button mat-raised-button class="width-200 dark-blue mt-25" type="button" (click)="scrollToNext(-1)"
        [disabled]="isSubmitted"> {{ 'QUESTIONNAIRE.BUTTONS.START_QUESTIONNAIRE' | translate }} </button>
    </section>

    <section class="questions" *ngIf="questions.length">
      <mat-form-field *ngIf="questionHoneypot"
                      [formGroup]="questionnaireHoneypotForm"
                      [ngClass]="'detect-name-question'"
      >
        <mat-label>{{ questionHoneypot.text }}</mat-label>
        <input matInput
               formControlName="answers"
               autocomplete="nameDetect"
               required
        >
      </mat-form-field>
      <app-radio-question [(completedQuestionsPercentage$)]="completedQuestionsPercentage$"
        [(answeredQuestions)]="answeredQuestions" [questionsNumber]="questions.length"
        *ngFor="let question of questions; let questionIndex=index;" [index]="questionIndex" [question]="question"
        [questionnaireForm]="questionnaireForm" [onChange]="scrollToNext">
      </app-radio-question>
      <div class="row">
        <div class="col">
          <button
            mat-raised-button
            id="finish-questionnaire"
            type="submit"
            class="col dark-blue mt-50 mb-100"
            (click)="doCaptchaCheck()"
            [disabled]="!isQuestionnaireValid() || isSubmitted"
            [ngClass]="'scroll-to-' + (questions.length)"
            *ngIf="!hasBotDetection || hasBotDetection && isCaptchaReady"
          >
            {{ 'QUESTIONNAIRE.BUTTONS.FINISH_QUESTIONNAIRE' | translate }} </button>
        </div>
        <div class="col">
          <div class="re-captcha-wrapper"
               *ngIf="hasBotDetection && captchaLang">
            <ngx-invisible-recaptcha
              #captchaElem
              [siteKey]="reCaptchaSiteKey"
              (ready)="handleCaptchaReady()"
              (success)="handleCaptchaSuccess($event)"
              [useGlobalDomain]="false"
              [badge]="'inline'"
              [hl]="captchaLang"
              [ngModel]="reCaptcha"
              [ngModelOptions]="{ standalone: true }"
            ></ngx-invisible-recaptcha>
          </div>
        </div>
      </div>

    </section>
  </div>
</app-preloader>

<div id="footer-progress-bar">
  <div class="container content">
    <div class="complete">{{ completedQuestionsPercentage$ | async }}% {{ 'QUESTIONNAIRE.STATUS' | translate }}</div>
    <div class="progress-bar float-left">
      <mat-progress-bar mode="determinate"  value="{{ completedQuestionsPercentage$ | async }}"></mat-progress-bar>
    </div>
  </div>
</div>
