import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
var HeaderDataService = /** @class */ (function () {
    function HeaderDataService() {
        this.content = new ReplaySubject();
    }
    HeaderDataService.prototype.setHeaderContent = function (content) {
        this.content.next(content);
    };
    HeaderDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderDataService_Factory() { return new HeaderDataService(); }, token: HeaderDataService, providedIn: "root" });
    return HeaderDataService;
}());
export { HeaderDataService };
