import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import { GenericResponse } from '../model/common';
import { Answers } from '../model/questionnaire/requests';

@Injectable({
  providedIn: 'root',
})
export class ParticipationService {

  constructor(private http: HttpClient) { }

  public createParticipation(projectId: string, panelistId: string, supplierId: string, lang: string, answers, captchaToken: string) {
    const request: Answers = {
      answers,
      panelistId,
      projectId,
      supplierId,
      lang,
      captchaToken,
    };

    return this.http.post<GenericResponse>(`${environment.api_url}/v1/web/participation/`, request,
                                           HttpUtils.getJsonContentTypeHeader());
  }

  public updateParticipation(panelistId: string, surveyStatus: number) {
    const request: object = {
      panelistId,
      surveyStatus,
    };
    return this.http.patch<GenericResponse>(`${environment.api_url}/v1/web/participation/`, request,
                                            HttpUtils.getJsonContentTypeHeader());
  }
}
