import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-radio-question',
  templateUrl: './radio-question.component.html',
  styleUrls: ['./radio-question.component.scss'],
})
export class RadioQuestionComponent implements OnInit {

  @Input() disabled;
  @Input() index;
  @Input() question;
  @Input() onChange;
  @Input() questionnaireForm;
  @Input() questionsNumber;
  @Input() answeredQuestions;
  @Input() completedQuestionsPercentage$;
  @Output() answeredQuestionsChange = new EventEmitter<number>();

  public answers: FormArray;
  public width: number;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.width = 0;
  }

  calculatePercentage(): void {

    if (this.completedQuestionsPercentage$ &&
      this.question.answered === undefined) {

      this.question.answered = true;

      this.answeredQuestions = this.answeredQuestions + 1;

      this.width = Math.round(100 * this.answeredQuestions / this.questionsNumber);

      if (this.answeredQuestions === (this.questionsNumber / 2)) {

        this.width = 50;

      } else if (this.width > 100) {

        this.width = 100;
      }

      this.completedQuestionsPercentage$.next(this.width);

      this.answeredQuestionsChange.emit(this.answeredQuestions);

    }
  }

  addAnswer(questionId, answerId): FormGroup {
    return this.formBuilder.group({
      questionId,
      answerId,
    });
  }

  addItem(questionId, answerId): void {

    this.answers = this.questionnaireForm.get('answers') as FormArray;
    const idx = this.answers.value.findIndex(answer => answer.questionId === questionId);
    // Remove first if the question is already added
    if (idx > -1) this.answers.removeAt(idx);
    this.answers.push(this.addAnswer(questionId, answerId));

    this.calculatePercentage();
  }

  onQuestionChange(index) {
    if (this.onChange) {
      this.onChange(index);
    }
  }
}
