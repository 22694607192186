import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpUtils } from '../util/http-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuestionnaireService = /** @class */ (function () {
    function QuestionnaireService(http) {
        this.http = http;
    }
    QuestionnaireService.prototype.getQuestionnaire = function (questionnaireId, panelistId, supplierId, lang) {
        var params = new HttpParams();
        params = params.append('panelistId', panelistId);
        params = params.append('supplierId', supplierId);
        params = params.append('lang', lang);
        var options = HttpUtils.getJsonContentTypeHeader();
        options['params'] = params;
        return this.http.get(environment.api_url + "/v1/web/questionnaire/" + questionnaireId, options);
    };
    QuestionnaireService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionnaireService_Factory() { return new QuestionnaireService(i0.ɵɵinject(i1.HttpClient)); }, token: QuestionnaireService, providedIn: "root" });
    return QuestionnaireService;
}());
export { QuestionnaireService };
