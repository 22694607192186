import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  errorWithClose(messageKey: string, duration: number = 10000) {
    this.show(messageKey, 'close', duration, null);
  }

  successWithClose(messageKey: string, duration: number = 10000, panelClass = ['alert', 'success']) {
    this.show(messageKey, 'close', duration, panelClass);
  }

  show(messageKey: string, buttonLabelKey: string, duration: number = 10000, panelClass) {
    this.snackBar.open(
      messageKey, buttonLabelKey,
      { duration, panelClass });

  }
}
