import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { OutcomeComponent } from './components/outcome/outcome.component';
import { SurveyOutcomeComponent } from './components/survey-outcome/survey-outcome.component';

const routes: Routes = [
  { path: 'questionnaire', component: QuestionnaireComponent },
  { path: 'questionnaire/:outcome', component: OutcomeComponent },
  { path: 'survey-outcome', component: SurveyOutcomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
