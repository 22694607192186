import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HeaderContent } from 'src/app/model/questionnaire/header';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public content: HeaderContent;

  private headerDataSubscription: Subscription;

  constructor(private headerDataService: HeaderDataService) { }

  ngOnInit() {
    this.headerDataSubscription = this.headerDataService.content.subscribe((headerContent: HeaderContent) => {
      this.content = headerContent;
    });
  }

  ngOnDestroy() {
    this.headerDataSubscription.unsubscribe();
  }
}
