/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio-question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i3 from "@angular/material/radio";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "./radio-question.component";
var styles_RadioQuestionComponent = [i0.styles];
var RenderType_RadioQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioQuestionComponent, data: {} });
export { RenderType_RadioQuestionComponent as RenderType_RadioQuestionComponent };
function View_RadioQuestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-radio-button", [["class", "mat-radio-button"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null], [1, "tabindex", 0], [1, "id", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0], [1, "aria-describedby", 0]], [[null, "click"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.addItem(_co.question._id, _v.context.$implicit._id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatRadioButton_0, i2.RenderType_MatRadioButton)), i1.ɵdid(1, 4440064, [[1, 4]], 0, i3.MatRadioButton, [[2, i3.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i5.UniqueSelectionDispatcher, [2, i6.ANIMATION_MODULE_TYPE], [2, i3.MAT_RADIO_DEFAULT_OPTIONS]], { id: [0, "id"], value: [1, "value"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_11 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit._id, ""); var currVal_12 = _v.context.$implicit.text[0].text; var currVal_13 = _co.disabled; _ck(_v, 1, 0, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).checked; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 1).color === "primary"); var currVal_4 = (i1.ɵnov(_v, 1).color === "accent"); var currVal_5 = (i1.ɵnov(_v, 1).color === "warn"); var currVal_6 = (0 - 1); var currVal_7 = i1.ɵnov(_v, 1).id; var currVal_8 = null; var currVal_9 = null; var currVal_10 = null; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_14 = _v.context.$implicit.text[0].text; _ck(_v, 2, 0, currVal_14); }); }
export function View_RadioQuestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "question"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "header", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "question-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "mat-radio-group", [["class", "mat-radio-group"], ["role", "radiogroup"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onQuestionChange(_co.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatRadioGroup]), i1.ɵdid(10, 1064960, null, 1, i3.MatRadioGroup, [i1.ChangeDetectorRef], null, { change: "change" }), i1.ɵqud(603979776, 1, { _radios: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RadioQuestionComponent_1)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "question"; var currVal_1 = ("scroll-to-" + _co.index); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.question.answers; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.index + 1); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.question.text[0].text; _ck(_v, 7, 0, currVal_3); }); }
export function View_RadioQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-radio-question", [], null, null, null, View_RadioQuestionComponent_0, RenderType_RadioQuestionComponent)), i1.ɵdid(1, 114688, null, 0, i9.RadioQuestionComponent, [i8.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioQuestionComponentNgFactory = i1.ɵccf("app-radio-question", i9.RadioQuestionComponent, View_RadioQuestionComponent_Host_0, { disabled: "disabled", index: "index", question: "question", onChange: "onChange", questionnaireForm: "questionnaireForm", questionsNumber: "questionsNumber", answeredQuestions: "answeredQuestions", completedQuestionsPercentage$: "completedQuestionsPercentage$" }, { answeredQuestionsChange: "answeredQuestionsChange" }, []);
export { RadioQuestionComponentNgFactory as RadioQuestionComponentNgFactory };
