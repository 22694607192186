import { Injectable } from '@angular/core';
import { HeaderContent } from '../model/questionnaire/header';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderDataService {

  public content = new ReplaySubject<HeaderContent>();

  setHeaderContent(content: HeaderContent) {
    this.content.next(content);
  }

}
